@font-face {
  font-family: 'Georgia';
  src: local('Georgia'), url(./fonts/Georgia.ttf) format('truetype');
}
@font-face {
  font-family: 'Georgia Bold';
  src: local('Georgia Bold'), url(./fonts/Georgia\ Bold.ttf) format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
}

html,
body {
  cursor: none;
  font-family: 'Georgia', sans-serif;
  background-color: white;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

form {
cursor: auto;
}

.webgl {
  cursor: auto;
  position: fixed;
  top: 0;
  left:0;
  outline: none;
}